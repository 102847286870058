import { Controller } from "@hotwired/stimulus"

// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

// import the translation files and create a translation mapping
import { Catalan } from "flatpickr/dist/l10n/cat.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { English } from "flatpickr/dist/l10n/default.js";

export default class extends Flatpickr {
  locales = {
    ca: Catalan,
    en: English,
    es: Spanish,
  }

  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: this.locale,
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "d/m/Y"
    };

    super.connect();
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return "";
    }
  }
}
